//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-735:_4204,_8444,_8144,_6684,_8256,_3776,_6356,_316;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-735')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-735', "_4204,_8444,_8144,_6684,_8256,_3776,_6356,_316");
        }
      }catch (ex) {
        console.error(ex);
      }